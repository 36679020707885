import {withSubscriber} from "mixins/withSubscriber";
import {withInputField, withInputFieldTargets} from "mixins/withInputField";
import {withToggleDatePickerOutsideListeners} from "mixins/withToggleDatePickerOutsideListeners";
import Flatpickr from "stimulus-flatpickr";
import {daysBetween} from "init/dates";

export default class extends withToggleDatePickerOutsideListeners(withInputField(withSubscriber(Flatpickr))) {
    static targets = withInputFieldTargets;
    initialize() {
        let iconNextString = document.querySelector('.js-BookingCalendar__next-month-arrow img').outerHTML
        this.config = {
            mode: "range",
            nextArrow: iconNextString,
            prevArrow: iconNextString,
            minDate: document.getElementById('reservation_lead_time').value,
            position: 'auto',
            clickOpens: false,
            closeOnSelect: false,
            locale: {
                firstDayOfWeek: 7
            },
        };
    }
    connect() {
        super.connect();
        if (this.fp && this.fp.calendarContainer) {
            let wrapper = document.createElement('div')
            wrapper.classList.add('Calendar__wrapper')
            wrapper.classList.add('Calendar__wrapper--withTooltip')
            wrapper.appendChild(this.fp.calendarContainer)
            document.body.appendChild(wrapper)

            const guideContainerNode = this.element.querySelector('.js-FloatingBookingBar__guide-container-wrapper').children[0]
            const guideContainerNodeClone = guideContainerNode.cloneNode(true)
            this.fp.calendarContainer.appendChild(guideContainerNodeClone)

            this.addYearSelector()
        }

        this.dateToObserveInRange = parseInt(this.element.dataset.dateToObserveInRange);
        this.element.addEventListener('open-toggle', this.openToggle.bind(this))
        this.element.addEventListener('open-toggle-and-check-date', this.openToggleAndCheckMinDate.bind(this))
    }

    yearChange(selectedDates, dateStr, instance) {
        let select = document.querySelector('.year-dropdown')
        select.value = instance.currentYear
    }

    addYearSelector() {
        if (this.fp.calendarContainer.querySelector('.year-dropdown')) return;
        let currYear = new Date().getFullYear();
        let select = document.createElement('select');
        select.classList.add('year-dropdown');

        for (let i = currYear; i <= currYear + 2; i++) {
            let option = document.createElement('option');
            option.value = i;
            option.innerHTML = i;
            select.appendChild(option);
        }
        this.fp.calendarContainer.querySelector('.flatpickr-current-month').append(select);
        select.addEventListener('change', (event) => {
            this.fp.changeYear(parseInt(event.target['value']))
        });
    }

    change(selectedDates, dateStr, instance) {
        this.notify(selectedDates, dateStr, instance);
        this.handleDateUpdate(selectedDates);
    }

    onNotification(event) {
        if (event.detail.channel === this.getChannel()) {
            const [selectedDates, dateStr, instance] = event.detail.args;
            if (this.fp !== instance) {
                this.fp.setDate(selectedDates);
                this.handleDateUpdate(selectedDates);
            }
        }
    }

    handleDateUpdate(selectedDates) {
        if (this.dateToObserveInRange + 1 <= selectedDates.length) {
            const date = selectedDates[this.dateToObserveInRange];
            this.setFieldTargetText(this.fp.formatDate(date, "m/d/Y"));
            this.setInputValue(this.fp.formatDate(date, "Y-m-d"));
        } else {
            this.setFieldTargetText(null);
            this.setInputValue(null);
        }
        const min_stay = parseInt(this.element.dataset.minStayLength)
        if (min_stay === selectedDates.length && daysBetween(selectedDates[0], selectedDates[1]) <= min_stay) {
            this.showMinDateToolTipError();
        } else if (min_stay === selectedDates.length) {
            this.closeToggle();
        }
    }

    showMinDateToolTipError() {
        // const error_msg = 'There\'s a 2 night minimum, please adjust your dates.';
        const error_msg = this.element.dataset.minStayText
        let nodeToAppendTo = this.fp.daysContainer.querySelector('.endRange');
        if (!nodeToAppendTo) {
            nodeToAppendTo = this.fp.daysContainer.querySelector('.startRange');
        }
        this.addToolTipNode(nodeToAppendTo, error_msg);
    }

    closeToggle() {
        super.closeToggle();
        this.fp.close();
    }

    openToggleAndCheckMinDate() {
        this.openToggle();
        const selectedDates = this.fp.selectedDates;
        if (
            selectedDates.length === 2 && daysBetween(selectedDates[0], selectedDates[1]) <= 3 ||
            selectedDates.length === 1
        ) {
            this.showMinDateToolTipError();
        }
    }

    openToggle() {
        super.openToggle();
        this.fp.open(null, this.element);
    }

    onOutsideClick(event) {
        if (!(this.element.contains(event.target) || this.fp.calendarContainer.contains(event.target))) {
            this.closeToggle();
        }
    }

    addToolTipNode (parent, msg) {
        const newDiv = document.createElement("div");
        newDiv.classList = ['date-tooltip js-date-tooltip'];
        const newContent = document.createTextNode(msg);
        newDiv.appendChild(newContent);
        parent.appendChild(newDiv)
    }

}
