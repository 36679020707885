import {withTogglesOutsideClick} from "mixins/withTogglesOutsideClick";

export const withToggleDatePickerOutsideListeners = controller => class extends withTogglesOutsideClick(controller) {
    registerOutsideClick() {
        this.boundCloseToggleFromOutsideClick = this.onOutsideClick.bind(this)
        document.addEventListener('click', this.boundCloseToggleFromOutsideClick)
        document.addEventListener('touchstart', this.boundCloseToggleFromOutsideClick)
        window.addEventListener('scroll', this.boundCloseToggleFromOutsideClick)
    }

    deregisterOutsideClick() {
        if (this.boundCloseToggleFromOutsideClick) {
            document.removeEventListener('click', this.boundCloseToggleFromOutsideClick)
            document.removeEventListener('touchstart', this.boundCloseToggleFromOutsideClick)
            window.removeEventListener('scroll', this.boundCloseToggleFromOutsideClick)
        }
    }

};
