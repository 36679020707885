export const withOutsideClick = controller => class extends controller {

    onOutsideClick(event) {}

    registerOutsideClick() {
        this.boundCloseToggleFromOutsideClick = this.onOutsideClick.bind(this)
        document.addEventListener('click', this.boundCloseToggleFromOutsideClick)
    }

    deregisterOutsideClick() {
        if (this.boundCloseToggleFromOutsideClick) {
            document.removeEventListener('click', this.boundCloseToggleFromOutsideClick)
        }
    }

};
