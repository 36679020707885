import { Controller } from "stimulus";
import Swiper from "swiper";

export default class extends Controller {
  connect() {
    this.mount()
  }

  mount() {
    const swiperContainer = this.element.querySelector(".villa-slider--swiper-container");

    new Swiper(swiperContainer, {
      centeredSlides: true,
      initialSlide: 1,
      loop: true,
      loopedSlides: 2,
      slidesPerView: 1.5,
      spaceBetween: 24,
      navigation: {
        prevEl: '.js-villa-slider--previous-button',
        nextEl: '.js-villa-slider--next-button',
      },
      breakpoints: {
        1200: {
          initialSlide: 1,
          loop: true,
          slidesPerView: 3,
          spaceBetween: 0,
          centeredSlides: true,
        }
      },
    });
  }
}
