export const withInputFieldTargets = ["fieldTitle", "fieldValue"]

export const withInputField = controller => class extends controller {
    static targets = withInputFieldTargets

    getFieldTitleTarget() {
        if (this.hasFieldTitleTarget)
            return this.fieldTitleTarget;
        console.error('getFieldTitleTarget error:', 'Please initialize fieldTitle in this controller.')
        return null;
    }

    setFieldTargetText(text) {
        this.getFieldTitleTarget().innerHTML = text;
    }

    getFieldValueTarget() {
        if (this.hasFieldValueTarget)
            return this.fieldValueTarget;
        console.error('getFieldValueTarget error:', 'Please initialize fieldValue in this controller.')
        return null;
    }

    setInputValue(v) {
        this.getFieldValueTarget().value = v
        // since we are changing the value programmatically we must trigger our own change event.
        this.getFieldValueTarget().dispatchEvent(new Event('change'));
    }

    getInputValue() {
        return this.getFieldValueTarget().value;
    }

};
