import { Controller } from "stimulus";
import Swiper from "swiper";

export default class extends Controller {
  connect() {
    this.resortSwiper = new Swiper('.Resort__information-carousel-container', {
      loop: true,
      navigation: {
        prevEl: '.Resort__information-carousel-previous-button',
        nextEl: '.Resort__information-carousel-next-button',
      },
    });

    this.resortSwiper.on('slideChangeTransitionEnd', () => {
      let captionElement = document.querySelector('.Resort__information-carousel-wrapper .swiper-slide-active')

      if (captionElement) {
       document.querySelector('.js-Resort__image-caption').innerHTML = captionElement.dataset.caption
      }
    });
  }

  resortHighlight(clickedElement) {
    if (!clickedElement.classList.contains('Resort__information-carousel-action-highlighted')) {
      let actionElement = document.querySelector('.Resort__information-carousel-action-highlighted')
      actionElement.classList.remove('Resort__information-carousel-action-highlighted')
      clickedElement.classList.add('Resort__information-carousel-action-highlighted')
    }
  }

  toggleHighlight(event) {
    let clickedElement = event.currentTarget;

    let resortCarousel = Array.from(clickedElement.classList).find((item) => item.includes('resort'))

    // if (resortCarousel) {
      return this.resortHighlight(clickedElement)
    // }

  }
}
