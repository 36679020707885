export const withSubscriber = controller => class extends controller {
    /**
     * Implement your code here.
     * @param event
     */
    onNotification(event) {
        event.preventDefault();
        if (event.detail.channel === this.getChannel() && event.target !== this.element) {
            // implement code.
        }
    }

    getExternalPublishers() {
        return this.externalPublishers || [];
    }

    subscribeTo(node) {
        this.getExternalPublishers().push(node);
    }

    initializeExternalPublishers() {
        if (this.element.hasAttribute('data-external-publishers')) {
            const nodes = document.querySelectorAll(this.element.dataset.externalPublishers);
            if (nodes) {
                nodes.forEach(this.subscribeTo.bind(this));
            }
        }
    }

    connect() {
        super.connect();
        setTimeout(() => {
            this.externalPublishers = [];
            this.initializeExternalPublishers();
            this.subscribe(this.element);
            this.getExternalPublishers().forEach(this.subscribe.bind(this));
        }, 500);
    }

    disconnect() {
        this.unsubscribe(this.element);
        this.getExternalPublishers().forEach(this.unsubscribe.bind(this));
    }

    subscribe(node) {
        node = node || this.element;
        const event = new CustomEvent("subscribe", {
            bubbles: true,
            detail: {
                channel: this.getChannel(),
                subscriber: this.element
            }
        });

        node.dispatchEvent(event);

        this.boundOnNotification = this.onNotification.bind(this);
        this.element.addEventListener('notification', this.boundOnNotification);
    }

    unsubscribe(node) {
        const event = new CustomEvent("unsubscribe", {
            bubbles: true,
            detail: { channel: this.getChannel() }
        });
        node.dispatchEvent(event);

        if (this.boundOnNotification) {
            this.element.removeEventListener('notification', this.boundOnNotification)
        }
    }

    getChannel() {
        if (this.element.hasAttribute('data-channel')) {
            return this.element.dataset.channel;
        }
        return 'default';
    }

    notify(...args) {
        const event = new CustomEvent("notify", {
            bubbles: true,
            detail: {
                channel: this.getChannel(),
                subscriber: this.element,
                args
            }
        });
        this.element.dispatchEvent(event);
        this.getExternalPublishers().forEach(node => node.dispatchEvent(event));
    }
}
