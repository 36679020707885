import {Controller} from "stimulus";
import {withInputField} from "mixins/withInputField";
import {withTogglesOutsideClick} from "mixins/withTogglesOutsideClick";
import {withPopUp} from "mixins/withPopUp";

export default class extends withPopUp(withInputField(withTogglesOutsideClick(Controller))) {
    static targets = [ "fieldTitle", "fieldValue", "dropDown", "dropDownTrigger", "inputOption"]
    closeToggle() {
        super.closeToggle();
        this.closeDropDown();
    }

    openToggle() {
        super.openToggle();
        this.openDropDown();
    }

    setFieldTargetText(e) {
        const checkedValues = Array.from(this.element.querySelectorAll('input'))
            .filter(el => el.checked)
            .map(el => el.dataset.labelText)
        super.setFieldTargetText(checkedValues.length > 0 ? checkedValues.join(', ') : 'Resorts')
    }

}
