import {Controller} from "stimulus";
import {withInputField} from "mixins/withInputField";
import {withTogglesOutsideClick} from "mixins/withTogglesOutsideClick";
import {withPopUp} from "mixins/withPopUp";

export default class extends withPopUp(withInputField(withTogglesOutsideClick(Controller))) {
    static targets = ["fieldTitle", "fieldValue", "dropDown", "dropDownTrigger", "incrementor", "decrementor"]
    connect() {
        this.minValue = parseInt(this.fieldValueTarget.getAttribute('min'));
        this.maxValue = parseInt(this.fieldValueTarget.getAttribute('max'));
        this.fieldValueTarget.addEventListener('change', (e) => this.setFieldTargetText(e.target.value));
    }

    validate() {
        const currentValue = parseInt(this.getInputValue());
        this.checkAndSetDisablingButtons(currentValue);
        if (currentValue > this.maxValue)
            this.setInputValue((this.maxValue).toString());
        if (currentValue < this.minValue)
            this.setInputValue((this.minValue).toString());
    }

    checkAndSetDisablingButtons(i) {
        this.incrementorTarget.disabled = parseInt(i) >= this.maxValue && !this.incrementorTarget.disabled;
        this.decrementorTarget.disabled = parseInt(i) <= this.minValue && !this.decrementorTarget.disabled;
    }

    stepUp() {
        const currentValue = parseInt(this.getInputValue());
        if (currentValue < this.maxValue)
            this.setInputValue((currentValue + 1).toString());
    }

    stepDown() {
        const currentValue = parseInt(this.getInputValue());
        if (currentValue > this.minValue)
            this.setInputValue((currentValue - 1).toString());
    }

    setInputValue(v) {
        super.setInputValue(v);
        this.setFieldTargetText(v);
        this.checkAndSetDisablingButtons(v);
    }

    setFieldTargetText(v) {
        super.setFieldTargetText( v + ' ' + 'Guests');
    }

    closeToggle() {
        super.closeToggle();
        this.closeDropDown();
    }

    openToggle() {
        super.openToggle();
        this.openDropDown();
    }

}
