import { Controller } from "stimulus";
import {withPublisher} from "mixins/withPublisher";
import { serializeFormData, queryString } from 'init/forms'
import {daysBetween} from "init/dates";

export default class extends withPublisher(Controller) {
  static targets = ["form", "arrival"];

  search(event) {
    if (event) event.preventDefault();
    let form = this.formTarget
    let data = serializeFormData(form)

    let searchParams = {
      checkin_date: data.check_in_date_hidden,
      checkout_date: data.check_out_date_hidden,
      resorts: data.resorts,
    }

    if (data.guests !== '' && data.guests !== '0') {
      searchParams.guests = data.guests
    }

    searchParams = Object.entries(searchParams).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
    let query = queryString(searchParams)
    if (query) query = `?${query}`

    let flatpickrEl = document.querySelector('.FloatingBookingBar__field-container--1')
    let minStay = parseInt(flatpickrEl.dataset.minStayLength)

    if (
        searchParams.hasOwnProperty('checkin_date') && !searchParams.hasOwnProperty('checkout_date') ||
        !searchParams.hasOwnProperty('checkin_date') && searchParams.hasOwnProperty('checkout_date') ||
        searchParams.hasOwnProperty('checkin_date') && searchParams.hasOwnProperty('checkout_date') &&
        daysBetween(new Date(searchParams['checkin_date']), new Date(searchParams['checkout_date'])) <= minStay
    ) {
      this.arrivalTarget.dispatchEvent(new Event('open-toggle-and-check-date'))
    } else {
      window.location.href = `/stay${query}`
    }
  }

}
