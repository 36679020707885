export const withPopUpTargets = ["dropDown", "dropDownTrigger"]

export const withPopUp = controller => class extends controller {
    static targets = withPopUpTargets

    closeDropDown() {
        this.getDropDownTarget().classList.add(this.getDropDownHideClass())
        this.getDropDownTrigger().removeAttribute('aria-expanded')
    }

    openDropDown() {
        const dropDown = this.getDropDownTarget()
        dropDown.classList.remove('offscreen')
        dropDown.classList.remove(this.getDropDownHideClass())
        this.getFirstFocusableElement(dropDown).focus();
        this.getDropDownTrigger().setAttribute('aria-expanded', 'true');
        if (this.offscreen(dropDown)) {
            dropDown.classList.add('offscreen')
        }
    }

    offscreen(el) {
        let rect = el.getBoundingClientRect();
        return (
            (rect.x + rect.width) < 0 ||
            (rect.y + rect.height) < 0 ||
            (
                (rect.x + rect.width) > window.innerWidth ||
                (rect.y + rect.height) > window.innerHeight
            )
        );
    }

    getFirstFocusableElement(el) {
        return el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')[0];
    }

    getDropDownHideClass() {
        if (this.getDropDownTarget().hasAttribute("data-popup-hide-class")) {
            return this.getDropDownTarget().dataset.popupHideClass
        }
        // todo: create generic class for hiding. like d-none.
        return 'FloatingBookingBar__hidden';
    }

    getDropDownTarget() {
        if (this.hasDropDownTarget)
            return this.dropDownTarget;
        console.error('getDropDownTarget error:', 'Please initialize dropDown in this controller.')
        return null;
    }

    getDropDownTrigger() {
        if (this.hasDropDownTriggerTarget)
            return this.dropDownTriggerTarget;
        return this.element;
    }

};
