// Builds query string from form data with no special processing
export function formParams(form) {
  return filteredFormInputs(form)
    .map(
      input =>
        `${encodeURIComponent(input.name)}=${encodeURIComponent(input.value)}`
    )
    .join("&");
}

export function queryString(attrs) {
  return Object.keys(attrs)
    .map(
      key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(attrs[key])}`
    )
    .join("&");
}

function filteredFormInputs(form) {
  return Array.from(form.elements).filter(
    field =>
      field.name &&
      !field.disabled &&
      field.type !== "file" &&
      field.type !== "reset" &&
      field.type !== "submit" &&
      field.type !== "button" &&
      (field.checked || ["radio", "checkbox"].indexOf(field.type) == -1)
  );
}

export function serializeFormData(form) {
  let serialized = {};
  filteredFormInputs(form).forEach(field => {
    if (field.type === "checkbox") {
      if (!serialized[field.name]) serialized[field.name] = [];
      if (field.checked) serialized[field.name].push(field.value);
    } else {
      serialized[field.name] = field.value;
    }
  });
  return serialized;
}