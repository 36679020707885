import "components/content_banner/content_banner";
import "components/floating_booking_bar/floating_booking_bar";
import "components/resorts_module/resorts_module";
import "components/review_carousel/review_carousel";
import "components/section_hero_banner/section_hero_banner";
import "components/section_social_feed/section_social_feed";
import "components/section_the_difference/section_the_difference";
import "components/special_offer/special_offer";
import "components/template_home/template_home";
import "components/villa_slider/villa_slider";
import "components/image_side_content/image_side_content";