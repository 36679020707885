import { Controller } from "stimulus";
import Swiper from "swiper";

export default class extends Controller {
  connect = () => window.addEventListener("load", () => this.mount());

  mount = () => {
    const swiperContainer = this.element.querySelector(".swiper-container");

    return new Swiper(swiperContainer, {
      centeredSlides: true,
      centeredSlidesBounds: true,
      initialSlide: 1,
      loop: true,
      loopedSlides: 2,
      navigation: {
        prevEl: '.js-review-carousel-button-prev',
        nextEl: '.js-review-carousel-button-next',
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets"
      },
      slidesPerView: 1,
    });
  };
}
