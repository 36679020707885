export const withToggles = controller => class extends controller {
    getNodesWithOpenToggleClassDataSet() {
        return this.element.querySelectorAll('[data-open-toggle-class]')
    }

    getNodesWithClosedToggleClassDataSet() {
        return this.element.querySelectorAll('[data-close-toggle-class]')
    }

    addOpenToggleClassToNodes() {
        this.getNodesWithOpenToggleClassDataSet().forEach(el => el.classList.add(el.dataset.openToggleClass))
    }

    removeOpenToggleClassFromNodes() {
        this.getNodesWithOpenToggleClassDataSet().forEach(el => el.classList.remove(el.dataset.openToggleClass))
    }

    addCloseToggleClassToNodes() {
        this.getNodesWithClosedToggleClassDataSet().forEach(el => el.classList.add(el.dataset.closeToggleClass))
    }

    removeCloseToggleClassFromNodes() {
        this.getNodesWithClosedToggleClassDataSet().forEach(el => el.classList.remove(el.dataset.closeToggleClass))
    }

    toggle() {
        if (this.isOpen()) {
            this.closeToggle();
        } else {
            this.openToggle();
        }
    }

    closeToggle() {
        this.removeOpenToggleClassFromNodes();
        this.addCloseToggleClassToNodes();
        this.element.dataset.isOpen = 'false';
    }

    // todo
    /** @deprecated */
    emitOpenFieldAndCloseOtherFieldsEvent() {
        const closeOtherToggles = new CustomEvent("close-fields", { bubbles: true});
        this.element.dispatchEvent(closeOtherToggles);
    }

    openToggle() {
        this.element.dataset.isOpen = 'true';
        this.addOpenToggleClassToNodes();
        this.removeCloseToggleClassFromNodes();
    }

    isOpen() {
        return this.element.hasAttribute("data-is-open") && this.element.dataset.isOpen === 'true';
    }

};
