import {withOutsideClick} from "mixins/withOutsideClick";
import {withToggles} from "mixins/withToggles";

export const withTogglesOutsideClick = controller => class extends withOutsideClick(withToggles(controller)) {
    closeToggle() {
        super.closeToggle();
        this.deregisterOutsideClick();
    }

    openToggle() {
        super.openToggle();
        this.registerOutsideClick();
    }

    onOutsideClick(event) {
        if (!this.element.contains(event.target)) {
            this.closeToggle();
        }
    }

};
