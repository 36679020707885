export const withPublisher = controller => class extends controller {
    connect() {
        super.connect();
        this.subscribers = {};
        this.initializeEventListeners();
    }

    initializeEventListeners() {
        this.boundOnSubscriberNotification = this.onSubscriberNotification.bind(this);
        this.element.addEventListener('notify', this.boundOnSubscriberNotification);

        this.boundonSubscribe = this.onSubscribe.bind(this);
        this.element.addEventListener('subscribe', this.boundonSubscribe);

        this.boundOnUnsubscribe = this.onUnsubscribe.bind(this);
        this.element.addEventListener('unsubscribe', this.boundOnUnsubscribe);
    }

    onSubscribe(event) {
        if (!this.subscribers.hasOwnProperty(event.detail.channel)) {
            this.subscribers[event.detail.channel] = [];
        }
        this.subscribers[event.detail.channel].push(event.detail.subscriber)
    }

    onUnsubscribe(event) {
        if (this.subscribers.hasOwnProperty(event.detail.channel)) {
            this.subscribers[event.detail.channel] = this.subscribers[event.detail.channel].filter(el => el !== event.detail.subscriber)
        }
    }

    onSubscriberNotification(event) {
        event.preventDefault();
        this.notifySubscribers(event);
    }

    sendNotificationToSubscribers(channel, ...args) {
        const event = new CustomEvent("notify", {
            bubbles: true,
            detail: {
                channel: channel,
                subscriber: this.element,
                args
            }
        });
        this._notifySubscribers(event);
    }

    notifySubscribers(event) {
        this._notifySubscribers(event)
    }

    _notifySubscribers(event) {
        if (this.subscribers.hasOwnProperty(event.detail.channel) && this.subscribers[event.detail.channel].length > 0) {
            this.subscribers[event.detail.channel].forEach(el => {
                if (el !== event.detail.subscriber) {
                    el.dispatchEvent(new CustomEvent("notification", event))
                }
            })
        }
    }
}
