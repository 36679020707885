// format date MM/DD/YY
export function dateMMDDYY(date) {
  const datePattern = /^(\d{2})-(\d{2})-(\d{4})$/;
  const [, month, day, year] = datePattern.exec(date);
  return `${month}/${day}/${year.slice(-2)}`;
}

function treatAsUTC(date) {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

export function daysBetween(startDate, endDate) {
  var millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}
